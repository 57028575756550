
import { Component, Vue } from 'vue-property-decorator'
import { AgentDetail } from '@/types/agent'

@Component
export default class Detail extends Vue {
  private info: AgentDetail = {
    agentName: '',
    roleId: '',
    agentId: '',
    contacts: '',
    contactNumber: '',
    province: '',
    city: '',
    area: '',
    address: '',
    remarks: ''
  }

  get agentId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  // 代理商详情
  getDetail () {
    this.$axios.get(this.$apis.agent.selectAgentByAgentId, {
      agentId: this.agentId
    }).then((res) => {
      this.info = res
    })
  }
}
